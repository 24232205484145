import { Pipe, PipeTransform } from '@angular/core';
import { Translation, TranslationService } from '../services/translation.service';

@Pipe({
  name: 'translation',
})
export class TranslationPipe implements PipeTransform {
  constructor(private translateService: TranslationService) {
  }

  transform(index: string, field: string, translations: Translation[]): string {
    return this.translateService.translate(index, field, translations);
  }
}
