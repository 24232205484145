import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'list-text',
  template: `
    <ng-container [ngTemplateOutlet]="getTemplate()"></ng-container>

    <ng-template #labelTemplate>
      <label [ngClass]="getClassList()" class="label">{{ text }}</label>
    </ng-template>

    <ng-template #titleTemplate>
      <div [ngClass]="getClassList()" class="title">{{ text }}</div>
    </ng-template>

    <ng-template #descriptionTemplate>
      <p [ngClass]="getClassList()" class="description" [innerHTML]="text"></p>
    </ng-template>
  `,
  styles: [`

    .label {
      font-size: 14px;
      font-weight: 600;
    }

    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.5px;
    }

    .margin-4 {
      margin-bottom: 4px;
      display: block;
    }

    .margin-8 {
      margin-bottom: 8px;
      display: block;
    }

    .margin-16 {
      margin-bottom: 16px;
      display: block;
    }

    .margin-24 {
      margin-bottom: 24px;
      display: block;
    }

    .description {
      font-size: 14px;
    }

    .description.bold {
      font-weight: bold;
    }

    .red {
      color: #BD0000;
    }

    .black {
      color: #000;
    }

    .orange {
      color: #E07F25;
    }

    .green {
      color: #0B871F;
    }

    .blue {
      color: #495D92;
    }

  `]
})
export class ListTextComponent implements OnInit {
  @Input() type: 'label' | 'title' | 'description' | '' = '';
  @Input() bold: boolean = false;
  @Input() text: any = '';
  @Input() color: 'red' | 'orange' | 'black' | 'green' | 'blue' = 'black';
  @Input() margin: '4' | '8' | '16' | '24' | 'none';

  @ViewChild('labelTemplate', { static: false }) labelTemplate: TemplateRef<any>;
  @ViewChild('titleTemplate', { static: false }) titleTemplate: TemplateRef<any>;
  @ViewChild('descriptionTemplate', { static: false }) descriptionTemplate: TemplateRef<any>;

  ngOnInit() {
  }

  getTemplate() {
    switch (this.type) {
      case 'label':
        return this.labelTemplate;
      case 'title':
        return this.titleTemplate;
      case 'description':
        return this.descriptionTemplate;
      default:
        return null;
    }
  }

  getClassList() {
    return {
      [`margin-${ this.margin }`]: this.margin !== undefined,
      [this.color]: this.color !== undefined,
      'bold': this.bold && this.type === 'description',
    };
  }
}
