import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-form-flex',
  template: `
    <div
      class="content"
      [fxLayoutGap]="calculateGap(props)"
      [fxLayoutGap.xs]="calculateGapXs(props)"
      [fxLayout]="props.fxLayout || 'row'"
      [fxLayout.xs]="props.fxLayoutXs || 'column'"
      fxFlexFill
    >
      <formly-field
        [fxFlex]="f.props.fxFlex || 100"
        [fxFlex.xs]="f.props.fxFlexXs || 100"
        *ngFor="let f of field.fieldGroup" [field]="defaultField(f)">
      </formly-field>
    </div>
    <div>
  </div>
  `,
})
export class FlexLayoutType extends FieldType implements OnInit {

  ngOnInit(): void {
    this.field.fieldGroup = this.field.fieldGroup.map(fg => {
      return Object.assign(fg, { props: { appearance: 'outline', ...fg.props } });
    });
  }

  public calculateGap(props) {
    const { fxLayoutGap = '24px', fxLayout } = props;
    if (fxLayout === 'column' || this._isMobile()) {
      return '0';
    }
    return fxLayoutGap;
  }

  public calculateGapXs(props) {
    const { fxLayoutGapXs = '8px', fxLayoutXs } = props;
    if (fxLayoutXs === 'column' || this._isMobile()) {
      return '0';
    }
    return fxLayoutGapXs;
  }

  public defaultField(field) {
    return field;
  }

  private _isMobile(): boolean {
    return window.innerWidth <= 600;
  }


}
