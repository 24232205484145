import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { map } from 'rxjs/operators';
import { Organization } from '../models/organization';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrganizationEntityService extends BaseEntityService<Organization> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'organization', plural: 'organizations' },
      routerParamsService
    );
    this.nameId = 'organizationId';
  }

  getWhitelabel(nameUrlSafe: string) {
    const body = { organization: { nameUrlSafe } };
    return this.httpClient
      .post([this.entityUrl, 'whitelabel'].join('/'), body)
      .pipe(map((response: any) => response[this.singularEntityName]));
  }
}
