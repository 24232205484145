import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Observable } from 'rxjs';
import { PersonRegistration } from '../models/person';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PersonRegistrationsEntityService extends BaseEntityService<PersonRegistration> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'personRegistration', plural: 'personRegistrations', url: 'person-registrations' },
      routerParamsService
    );
    this.nameId = 'personRegistrationId';
  }

  getRegistration(document: string): Observable<PersonRegistration> {
    return this.getWithQuery({ document }, true).pipe(
      map((response: any) => {
        return response[this.singularEntityName];
      }),
    );
  }
}
