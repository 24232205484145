import { Injectable } from '@angular/core';
import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {
  private defaultNumber: string = '5511912742944';
  /*
  * index = orgId
  * value = number by org
  * */
  private alternativeByOrgIdNumber = {
  };

  constructor(private organizationService: OrganizationService) {
  }

  sendMessage(text: string, extra?: { oId: boolean }) {
    const { oId } = extra || {};
    let urlBuild: string[] = [
      'https://wa.me',
      this.findNumber(oId),
      `?text=${ encodeURIComponent(text) }`
    ];
    window.open(urlBuild.join('/'), '_blank');
  }


  private findNumber(useOrganizationId: boolean): string {
    const { id } = this.organizationService.getOrganization();
    if (useOrganizationId && !!this.alternativeByOrgIdNumber[id]) {
      return this.alternativeByOrgIdNumber[id];
    }
    return this.defaultNumber;
  }
}
