import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationService } from '../services/organization.service';
import { OrganizationEntityService } from '../../state/entity-services/organization-entity.service';

@Injectable()
export class OrganizationWhitelabelGuard implements CanActivate {
  constructor(
    private organizationService: OrganizationService,
    private organizationEntityService: OrganizationEntityService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { organization } = route.params;
    return this.organizationEntityService.getWhitelabel(organization).pipe(
      map(organization => {
        return this.organizationService.setOrganization(organization);
      })
    );
  }

}


