import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

interface MimePattern {
  mime: string;
  pattern: number[];
  mask: number[];
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadMimeValidatorService {
  private mimes: MimePattern[] = [
    {
      mime: 'image/jpeg',
      pattern: [0xFF, 0xD8, 0xFF],
      mask: [0xFF, 0xFF, 0xFF],
    },
    {
      mime: 'image/png',
      pattern: [0x89, 0x50, 0x4E, 0x47],
      mask: [0xFF, 0xFF, 0xFF, 0xFF],
    },
    {
      mime: 'application/pdf',
      pattern: [0x25, 0x50, 0x44, 0x46], // '%PDF' in ASCII
      mask: [0xFF, 0xFF, 0xFF, 0xFF],
    },
    {
      mime: 'image/heic',
      pattern: [0x00, 0x00, 0x00, 0x18, 0x66, 0x74, 0x79, 0x70, 0x68, 0x65, 0x69, 0x63],
      mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
    }
    // você pode expandir essa lista @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
  ];

  constructor() {
  }

  private check(bytes: Uint8Array, mime: MimePattern): boolean {
    for (let i = 0; i < mime.mask.length; ++i) {
      if ((bytes[i] & mime.mask[i]) !== mime.pattern[i]) {
        return false;
      }
    }
    return true;
  }

  validateMimeType(file: File): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const blob = file.slice(0, Math.max(...this.mimes.map(mime => mime.pattern.length))); // lê os primeiros bytes necessários para cada padrão

      const reader = new FileReader();
      reader.onloadend = (e: ProgressEvent<FileReader>) => {
        if (e.target?.readyState === FileReader.DONE) {
          const bytes = new Uint8Array(e.target.result as ArrayBuffer);

          for (const mime of this.mimes) {
            if (this.check(bytes, mime)) {
              observer.next(`Mime: ${ mime.mime } <br> Browser: ${ file.type }`);
              observer.complete();
              return;
            }
          }

          observer.next(`Mime: unknown <br> Browser: ${ file.type }`);
          observer.complete();
        }
      };
      reader.onerror = () => {
        observer.error('Erro ao ler o arquivo.');
      };
      reader.readAsArrayBuffer(blob);
    });
  }
}
