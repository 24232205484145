import { Injectable } from '@angular/core';
import heic2any from 'heic2any';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageConverterService {

  constructor(
  ) {
  }

  renameFileExtension(fileName: string): string {
    return `${fileName.replace(/\.[^/.]+$/, "")}.jpeg`;
  }

  isHeicFile(file: File): boolean {
    const fileExtension = file?.name?.split('.').pop()?.toLowerCase();
    const mimeType = file.type;
    return fileExtension === 'heic' || fileExtension === 'heif' || mimeType === 'image/heic' || mimeType === 'image/heif';
  }

  convertHeicToJpeg(file: File): Observable<any> {
    // @ts-ignore
    return from(heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.8 // Define a qualidade da imagem convertida
    })).pipe(
      map(conversionResult => {
        return conversionResult as Blob;
      }),
    );
  }
}
