import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-masked-type',
  template: `
    <mat-form-field class="full-width" appearance="outline" [floatLabel]="props.floatLabel || 'auto'">
      <mat-label *ngIf="props.label">{{ props.label }}</mat-label>
      <input
        matInput
        [id]="id"
        [mask]="mask"
        [type]="type || 'text'"
        [readonly]="props.readonly"
        [required]="props.required"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [tabIndex]="props.tabindex"
        [placeholder]="props.placeholder"
        [dropSpecialCharacters]="true"
        [decimalMarker]="props?.decimalMarker || '.'"
        [thousandSeparator]="props?.thousandSeparator || ','"
      />
    </mat-form-field>
    <!--    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">-->
    <!--      Please enter a valid email address-->
    <!--    </mat-error>-->

  `,
})
export class FormlyFieldMaskedTypeComponent extends FieldType<FieldTypeConfig> {
  get type() {
    return this.props.type ?? 'text';
  }

  get mask() {
    return this.props['mask'] ?? '(00) 0000-0000';
  }
}
