import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LegalTermVersions, PersonLegals } from '../models/person-legals';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PersonLegalsEntityService extends BaseEntityService<PersonLegals> {
  public lLegalTermVersions: LegalTermVersions[];

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'personLegal', plural: 'personLegals', url: 'person-legals' },
      routerParamsService
    );
    this.nameId = 'personLegalId';
  }

  set lastLegalTermVersions(legalTermVersions: LegalTermVersions[]) {
    this.lLegalTermVersions = legalTermVersions;
  }

  get lastLegalTermVersions(): LegalTermVersions[] {
    return this.lLegalTermVersions || [];
  }

  getLegalTermVersion(personId: number) {
    const person = { id: personId };
    return this.httpClient.post(`${ this.entityUrl }/find-pending-legal-term-versions`, { person }).pipe(
      map((response: any) => {
        return response['legalTermVersions'];
      })
    );
  }

  acceptTerms(legalTermVersions: LegalTermVersions[], personId: number) {
    const person = { id: personId };
    const personLegalSub = legalTermVersions.map(legalTermVersion => {
      return this.add({
        legalTermVersion: { id: legalTermVersion.id },
        person
      });
    });
    return forkJoin(personLegalSub);
  }
}
