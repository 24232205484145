import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { PersonEntityService } from '../../state/entity-services/person-entity.service';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { catchError, map } from 'rxjs/operators';
import { OrganizationService } from '../services/organization.service';
import { OrganizationEntityService } from '../../state/entity-services/organization-entity.service';
import { AmplitudeService } from '../services/amplitude.service';

@Injectable()
export class PatientGuard implements CanActivate {
  constructor(
    private amplitudeService: AmplitudeService,
    private organizationService: OrganizationService,
    private organizationEntityService: OrganizationEntityService,
    private jwtAuthService: JwtAuthService,
    private personEntityService: PersonEntityService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { person_id, signature, organization: qOrganization, } = route.queryParams;
    const user = this.jwtAuthService.getUser();
    if (person_id || user?.id) {
      return this.personEntityService.crmPersons(person_id || user?.id).pipe(
        switchMap(crmPerson => {
          const { patientAppDigitalStatus = 'NO_STATUS', id: crmPersonId } = crmPerson || {};
          if (patientAppDigitalStatus === 'FINISHED') {
            this.redirectTo(`/paciente/relatorio-saude/${ person_id || user?.id }`);
            return of(true);
          } else if (patientAppDigitalStatus === 'WAITING_FORM_SUBMISSION') {
            if (signature && qOrganization && person_id) {
              return of(true);
            }
            return this.personEntityService.generateSelfieLink(user.id).pipe(
              map(res => {
                const [baseUrl, queryParams] = res.split('.net/');
                const [organization, , queryParamsString] = queryParams.split('/');
                const signature = queryParamsString.split('signature=')[1];
                this.redirectTo('/paciente/confirmacao/idade', { person_id: user.id, signature, organization });
                return true;
              }),
              catchError(() => of(true))
            );
          } else {
            this.redirectTo(`/paciente/status/${ patientAppDigitalStatus }`, { personId: person_id, crmPersonId });
          }
          return of(true);
        })
      );
    }

    if (qOrganization) {
      return this.organizationEntityService.getWhitelabel(qOrganization).pipe(
        map(organizationResponse => {
          this.organizationService.setOrganization(organizationResponse);
          return true;
        })
      );
    }
    return of(true);
  }

  redirectTo(path: string, queryParams = {}) {
    this.router.navigate([path], {
      queryParams,
    });
  }
}
