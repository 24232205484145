<div (click)="expandableAll()" [class.disable-top]="disableTop" [class.divider]="divider" [class.expandable]="expandAllClick"
     class="item-list">
  <div *ngIf="iconName" class="icon-container">

    @if (iconName === 'dot') {
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" fill="#45464F"/>
      </svg>
    } @else {
      <mat-icon class="{{iconColor}}">{{ iconName }}</mat-icon>
    }
  </div>
  <div class="any-container">
    <ng-content></ng-content>
  </div>
  <div *ngIf="expand" class="expandable-icon">
    <mat-icon>unfold_more</mat-icon>
  </div>
</div>
