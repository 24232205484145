import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export interface ILayoutConf {
  toolbarNav?: boolean;
  menuCustom?: MenuCustom;
}


@Injectable({
  providedIn: 'root'
})
export class MenuLayoutService {
  public layoutConf: ILayoutConf = {
    toolbarNav: true,

  };
  layoutConfSubject = new BehaviorSubject<ILayoutConf>(this.layoutConf);
  layoutConf$ = this.layoutConfSubject.asObservable();

  menuCustomSubject = new Subject<MenuCustomAction>();
  menuCustom$ = this.menuCustomSubject.asObservable();

  constructor() {
  }

  setLayout(layoutConfSet: ILayoutConf) {
    this.layoutConf = { ...this.layoutConf, ...layoutConfSet };
    this.layoutConfSubject.next(this.layoutConf);
  }

}

export interface MenuCustomAction {
  firstButtonClick?: boolean;
  lastButtonClick?: boolean;
}

export interface MenuCustom {
  title?: string;
  firstButton: {
    icon: string;
  };
  lastButton?: {
    icon: string;
  };
}
