import { AfterViewInit, Directive, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { fromEvent } from 'rxjs';
import { map, pairwise, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[draggableBottomSheet]'
})
export class BottomSheetDraggableDirective implements AfterViewInit, OnDestroy {
  private startY: number = 0;
  private dragging: boolean = false;
  private threshold: number = 150; // Define o limite de arrasto para o dismiss

  constructor(
    private bottomSheetRef: MatBottomSheetRef,
    private elementRef: ElementRef,
    private zone: NgZone
  ) {
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      const dragStart$ = fromEvent(this.elementRef.nativeElement, 'touchstart').pipe(
        map((event: TouchEvent) => {
          this.startY = event.touches[0].clientY;
          this.dragging = true;
          return this.startY;
        })
      );

      const dragMove$ = fromEvent(document, 'touchmove').pipe(
        map((event: TouchEvent) => event.touches[0].clientY),
        pairwise(),
        map(([prevY, newY]) => newY - prevY)
      );

      const dragEnd$ = fromEvent(document, 'touchend').pipe(
        map(() => {
          this.dragging = false; // Reseta o estado de arrasto
        })
      );

      dragStart$.subscribe();
      dragEnd$.subscribe();

      dragMove$.pipe(
        takeUntil(dragEnd$)
      ).subscribe(deltaY => {
        if (!this.dragging) {
          return;
        }

        // Calcula o deslocamento total desde o início do arrasto
        let totalY = deltaY + (this.startY - deltaY);

        if (totalY > this.threshold) { // Checa se ultrapassou o limite
          this.zone.run(() => this.bottomSheetRef.dismiss());
          this.dragging = false; // Evita múltiplos disparos
        }
      });
    });
  }

  ngOnDestroy() {
    // Aqui você deveria limpar as inscrições se estiver utilizando-as
  }
}
