<mat-toolbar *ngIf="toolbarNav && menuCustom">
  <button (click)="actionFirstButton()" mat-icon-button>
    <mat-icon>{{ menuCustom.firstButton.icon }}</mat-icon>
  </button>
  <span class="example-spacer"></span>
  <span class="text-title">{{ menuCustom.title || organization.name }}</span>
  <span class="example-spacer"></span>
  <button *ngIf="menuCustom?.lastButton" (click)="actionLastButton()" mat-icon-button>
    <mat-icon>{{ menuCustom.lastButton.icon }}</mat-icon>
  </button>
  <button *ngIf="!menuCustom?.lastButton" (click)="openWhatsApp()" mat-icon-button matTooltip="Ajuda">
    <mat-icon>contact_support</mat-icon>
  </button>
</mat-toolbar>
<mat-toolbar *ngIf="toolbarNav && !menuCustom">
  <button (click)="toggle(sidenav)" mat-icon-button matTooltip="Menu">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="example-spacer"></span>
  <span>{{ organization.name }}</span>
  <span class="example-spacer"></span>
  <button (click)="openWhatsApp()" mat-icon-button matTooltip="Ajuda">
    <mat-icon>contact_support</mat-icon>
  </button>
</mat-toolbar>
<mat-sidenav-container [class.full]="!toolbarNav" class="app-container">
  <mat-sidenav #sidenav [class.mat-elevation-z4]="true" class="app-sidenav" mode="push">
    <mat-nav-list>
      <mat-list-item *ngIf="user" [activated]="false">
        <mat-icon matListItemIcon>person</mat-icon>
        <span matListItemTitle>{{ user?.personProperties?.name }}</span>
        <span matListItemLine>{{ user?.document }}</span>
      </mat-list-item>
      <mat-list-item (click)="logout()" *ngIf="user">
        <mat-icon matListItemIcon>logout</mat-icon>
        <span matListItemTitle>Sair</span>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div [class.full]="!toolbarNav" class="app-sidenav-content">
      <router-outlet/>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

