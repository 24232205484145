import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { PerformedExam } from '../models/exams';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PerformedExamsEntityService extends BaseEntityService<PerformedExam> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'performedExam', plural: 'performedExams', url: 'performed-exams' },
      routerParamsService
    );
    this.nameId = 'performedExamId';
  }

  remove(key: any) {
    return this.delete(key);
  }
}
