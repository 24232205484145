import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UtilsService } from './utils.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  translation: BehaviorSubject<Translation[]> = new BehaviorSubject([]);

  constructor(
    private utilsService: UtilsService,
    private httpClient: HttpClient) {
  }

  set(translation: Translation[]) {
    this.translation.next(translation);
  }

  get(): Observable<Translation[]> {
    return this.translation.asObservable();
  }

  translate(index: string, field: string, translations: Translation[]) {
    if (index && field && translations) {
      const translation = translations?.find(
        t => t.field === this.capitalizeFirstLetter(field),
      );
      if (translation) {
        const { values } = translation;
        return values[index] || index;
      } else {
        return index;
      }
    }
    return index;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getTranslations(queryParams?): Observable<Translation[]> {
    const params = queryParams ? this.utilsService.objectParams(queryParams) : undefined;
    return this.httpClient.get(`${ environment.apiUrl }/translations`, { params }).pipe(
      map(response => {
        const { translations = [] } = (response as any) || {};
        this.set(translations);
        return translations;
      }),
    );
  }
}

export interface Translation {
  field: string;
  values: Record<string, string>;
}
