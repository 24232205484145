import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, switchMap, tap, Unsubscribable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UtilsService } from '../../../shared/services/utils.service';
import { AutoUnsubscribe, CombineSubscriptions } from '../../../shared/decorators/auto-unsubscribe.decorator';
import { ImageConverterService } from '../../../shared/services/image-converter.service';
import * as Sentry from '@sentry/angular';
import { FileUploadMimeValidatorService } from './file-upload-mime-validator.service';


@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
@AutoUnsubscribe()
export class FileUploadComponent implements OnInit, OnDestroy {
  @Output() callback = new EventEmitter();
  @Input() typeButton: 'PRIMARY' | 'SECONDARY' = 'SECONDARY';
  @Input() width100: boolean;
  @Input() prefix: 'PERFORMED_EXAM' | 'REFERRAL' | 'EXAM_REQUEST' | 'PATIENTS' | 'EXAM_PARSE';
  @Input() showRemoveButton: boolean;
  @Input('titleButton') titleButtonOriginal = 'Upload de arquivos';
  titleButton;
  @Input() iconButton: string;
  public files: NgxFileDropEntry[] = [];
  loading: boolean;
  @Input() disableLoading: boolean;
  @CombineSubscriptions()
  private subscriptions: Unsubscribable;

  constructor(
    private fileUploadMimeValidatorService: FileUploadMimeValidatorService,
    private imageConverterService: ImageConverterService,
    private utilsService: UtilsService, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.setOriginalMsg();
    if (this.showRemoveButton) {
      this.titleButton = 'Arquivo enviado!';
    }
  }

  ngOnDestroy() {
  }

  setOriginalMsg() {
    this.titleButton = this.titleButtonOriginal;
    this.loading = false;
  }

  setLoading() {
    if (!this.disableLoading) {
      this.titleButton = 'Enviando...';
      this.loading = true;
    }
  }

  bytesToMegabytes(bytes: number): number {
    const bytesPerMegabyte = 1024 * 1024;
    return bytes / bytesPerMegabyte;
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          if (!this.isFileAllowed(file)) {
            this.utilsService.toast('Tipo de arquivo não permitido', 'error', 20, 'top');
            return;
          }

          if (this.bytesToMegabytes(file.size) >= 30) {
            this.utilsService.toast('Arquivo excede o limite de 20mb. Envie um arquivo com tamanho menor!', 'error', 20, 'top');
            return;
          }

          const { type: contentType, name } = file;

          this.setLoading();
          this.sendFileLoading(file.name);
          if (this.imageConverterService.isHeicFile(file)) {
            this.imageConverterService.convertHeicToJpeg(file)
              .pipe(
                switchMap(fileResultJpg => {
                  return this.submitFile(fileResultJpg, this.imageConverterService.renameFileExtension(name));
                })
              )
              .subscribe(
                {
                  error: err => {
                    this.utilsService.setErrorToast(err);
                  }
                }
              );
          } else {
            this.submitFile(file).subscribe(
              {
                error: err => {
                  this.utilsService.setErrorToast(err);
                }
              }
            );
          }
        });
      }
    }
  }


  submitFile(file: File, orignalNameFile = ''): Observable<FileResponse> {
    const { type: contentType } = file;
    try {
      if (!contentType) {
        throw new Error('contentType not found');
      }
    } catch (error) {
      this.utilsService.toast('Erro ao enviar arquivo!', 'error');
      Sentry.setExtras({ file: file });
      Sentry.captureException(error);
      return;
    }
    const fileToSend = file;
    return this.fileUploadMimeValidatorService.validateMimeType(file)
      .pipe(
        switchMap(() => {
          return this.http
            .post(`${ environment.apiUrl }/files`, {
              file: {
                prefix: this.prefix,
                contentType,
              },
            })
            .pipe(
              switchMap(response => {
                const { file: fileResp } = response as {
                  file: FileResponse;
                };
                return this.http.put(`${ fileResp.signedPutUrl }`, file).pipe(
                  map(() => {
                    return fileResp;
                  }),
                );
              }),
              tap(response => {
                const { url, signedGetUrl } = response;
                const { name } = file;
                this.setOriginalMsg();
                this.utilsService.toast('Arquivo enviado!', 'Ok', 4, 'top');
                this.callback.emit({
                  id: name ?? orignalNameFile,
                  loading: false,
                  name: name ?? orignalNameFile,
                  url,
                  signedGetUrl,
                });
              }),
              catchError(err => {
                this.setOriginalMsg();
                this.utilsService.setErrorToast(err);
                throw err;
              }),
            );
        })
      );
  }

  sendFileLoading(toId: any) {
    this.callback.emit({
      id: toId,
      loading: true,
      name: toId,
      url: '',
      signedGetUrl: '',
    });
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }

  public removeFile() {
    this.callback.emit({ url: null });
    this.titleButton = 'Upload de arquivos';
  }


  private isFileAllowed(file: any) {
    const mimeTypes = [
      'image/png',
      'application/pdf',
      'image/jpeg',
      'image/jpg',
      'image/heic',
    ];
    return mimeTypes.includes(file.type);
  }

}


export interface FileResponse {
  id?: string;
  loading?: boolean;
  signedPutUrl: string;
  url: string;
  signedGetUrl: string;
}
