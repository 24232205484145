import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'list-item',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnChanges {
  @Input() itemId: number;
  @Input() iconName: string;
  @Input() iconColor: string;
  @Input() disableTop: boolean;
  @Input() divider = true;
  @Input() hasExpandable = false;
  @Input() expand = false;
  @Input() expandAllClick = false;
  @Output() cbExpandable = new EventEmitter();


  expandable() {
    this.hasExpandable = !this.hasExpandable;
    this.cbExpandable.emit({ expand: this.hasExpandable, id: this.itemId });
  }

  expandableAll() {
    if (this.expandAllClick) {
      this.expandable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }


}
