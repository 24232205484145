import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { PatientSummary } from '../models/patient-summary';
import { JwtAuthService } from '../../shared/services/auth/jwt-auth.service';
import { Observable, tap, throwError } from 'rxjs';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PatientSummaryEntityService extends BaseEntityService<PatientSummary> {

  constructor(
    private jwtAuthService: JwtAuthService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'patientSummary', plural: 'patientSummary', url: 'patient-api/patient-summary-v2' },
      routerParamsService
    );
    this.nameId = 'personId';
  }

  // @ts-ignore
  override getCurrentFromApi(id: any): Observable<PatientSummary> {
    let { id: personId } = this.jwtAuthService.getUser() || {};

    if (!personId) {
      return throwError('User without id');
    }

    if (id) {
      personId = parseInt(id);
    }
    return this.add({ personId }, true).pipe(
      tap(response => {
        this.manualEntity = { ...response, id: personId };
      })
    );
  }
}
